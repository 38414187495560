import React, { useState, useEffect } from "react";
import { collection, getDocs, addDoc, Timestamp } from "firebase/firestore";
import { db } from "../../auth/firebaseConnection";
import Sidebar from "../../components/SideBar";
import Modal from "../../components/Modal";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import Pagination from '../../components/Paginacao';

import { toast } from "react-toastify";

const ClientList = () => {
  const navigate = useNavigate();
  const [clients, setClients] = useState([]);
  const [search, setSearch] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newClient, setNewClient] = useState({
    nome: "",
    cargoFuncao: "",
    cidade: "",
    estado: "",
    telefone: "",
    email: "",
    nomeEmpresa: "",
    interesse: "",
    status: "Novo Cliente",
    qualificacao: "",
    produto: "",
    valorProposta: "",
    observacao: "",
    canais: "",
    dataFechamento: "",
    dataInteracao: "",
    dataProposta: "",
    primeiroAtedimento: Timestamp.now(),
    responsavel: "",
  });

  // Paginação
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [paginatedClientes, setPaginatedClientes] = useState([]);
  
  useEffect(() => {
    const fetchClients = async () => {
      const querySnapshot = await getDocs(collection(db, "clientes"));
      const data = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setClients(data);
    };
    fetchClients();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewClient((prev) => ({ ...prev, [name]: value }));
  };

  const handleSaveClient = async () => {
    try {
      const clientToSave = {
        ...newClient,
        primeiroAtedimento: Timestamp.now(),
      };
      const docRef = await addDoc(collection(db, "clientes"), clientToSave);
      setClients((prev) => [...prev, { id: docRef.id, ...clientToSave }]);
      toast.success("Cliente adicionado com sucesso!");
      setIsModalOpen(false);
      setNewClient({
        nome: "",
        cargoFuncao: "",
        cidade: "",
        estado: "",
        telefone: "",
        email: "",
        nomeEmpresa: "",
        interesse: "",
        status: "Novo Cliente",
        qualificacao: "",
        produto: "",
        valorProposta: "",
        observacao: "",
        canais: "",
        dataFechamento: "",
        dataInteracao: "",
        dataProposta: "",
        primeiroAtedimento: Timestamp.now(),
        responsavel: "",
      });
    } catch (error) {
      console.error("Erro ao adicionar cliente:", error);
      toast.error("Erro ao adicionar cliente.");
    }
  };

  const filteredClients = clients.filter((client) =>
    client.nome.toLowerCase().includes(search.toLowerCase()) ||
    client.email.toLowerCase().includes(search.toLowerCase()) ||
    client.telefone.toLowerCase().includes(search.toLowerCase()) ||
    client.cidade.toLowerCase().includes(search.toLowerCase()) ||
    client.qualificacao.toLowerCase().includes(search.toLowerCase()) ||
    client.status.toLowerCase().includes(search.toLowerCase()) ||
    client.canais.toLowerCase().includes(search.toLowerCase())
  );

  // Atualizar clientes paginados quando `filteredClients` ou `currentPage` mudarem
  useEffect(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    setPaginatedClientes(filteredClients.slice(startIndex, endIndex));
  }, [filteredClients, currentPage]);

  const totalPages = Math.ceil(filteredClients.length / itemsPerPage);

  const onNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const onPrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleClientDetails = (client) => {
    navigate("/detalhe-cliente", { state: { client } });
  };

  return (
    <>
      <Sidebar />
      <div className="container">
        <div className="content">
          <div className="reader-cliente">
            <h1>Clientes</h1>
            <div className="gradient-bar">
              <input
                className="search-bar"
                type="text"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Pesquisar por nome/email/telefone/cidade/classificação/canais/status"
              />
              <button onClick={() => setIsModalOpen(true)}>Adicionar Cliente</button>
            </div>
          </div>
          <table>
            <thead>
              <tr>
                <th>Nome</th>
                <th>Telefone</th>
                <th>Cidade</th>
                <th>Classificação</th>
                <th>Status</th>
                <th>Detalhes</th>
              </tr>
            </thead>
            <tbody>
              {paginatedClientes.map((client) => (
                <tr key={client.id}>
                  <td>{client.nome}</td>
                  <td>{client.telefone}</td>
                  <td>{client.cidade}</td>
                  <td>{client.qualificacao}</td>
                  <td>{client.status}</td>
                  <td>
                    <button className="btn-icon" onClick={() => handleClientDetails(client)}>
                      <FontAwesomeIcon icon={faEye} />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="pagination">
          <Pagination
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            totalItems={filteredClients.length} // Total de itens filtrados
            onNextPage={onNextPage}
            onPrevPage={onPrevPage}
          />
        </div>

        {/* Exibe o total de clientes filtrados */}
        <div className="total-clientes">
          <p>Total de clientes filtrados: {filteredClients.length}</p>
        </div>
      </div>

      <Modal isOpen={isModalOpen} onRequestClose={() => setIsModalOpen(false)}>
        <h2>Adicionar Novo Cliente</h2>
        <form>
          <label>
            Nome:
            <input
              type="text"
              name="nome"
              value={newClient.nome}
              onChange={handleInputChange}
            />
          </label>
          <label>
            Cargo/Função:
            <input
              type="text"
              name="cargoFuncao"
              value={newClient.cargoFuncao}
              onChange={handleInputChange}
            />
          </label>
          <label>
            Cidade:
            <input
              type="text"
              name="cidade"
              value={newClient.cidade}
              onChange={handleInputChange}
            />
          </label>
          <label>
            Estado:
            <input
              type="text"
              name="estado"
              value={newClient.estado}
              onChange={handleInputChange}
            />
          </label>
          <label>
            Telefone:
            <input
              type="text"
              name="telefone"
              value={newClient.telefone}
              onChange={handleInputChange}
            />
          </label>
          <label>
            Email:
            <input
              type="email"
              name="email"
              value={newClient.email}
              onChange={handleInputChange}
            />
          </label>
          <label>
            Nome da Empresa:
            <input
              type="text"
              name="nomeEmpresa"
              value={newClient.nomeEmpresa}
              onChange={handleInputChange}
            />
          </label>
          <label>Interesse:</label>
          <select
            name="interesse"
            value={newClient.interesse}
            onChange={handleInputChange}
          >
            <option value="">Selecione</option>
            <option value="Consultoria">Consultoria</option>
            <option value="Imersão">Imersão</option>
            <option value="Mentoria Individual">Mentoria Individual</option>
            <option value=">Mentoria em Grupo">Mentoria em Grupo</option>
            <option value="Plataforma">Plataforma</option>
            <option value="Treinamento">Treinamento</option>
            <option value="Desenolvimento Web">Desenolvimento Web</option>
          </select>

          <label>Canal de Comunicação:</label>
          <select
            name="canais"
            value={newClient.canais}
            onChange={handleInputChange}
          >
            <option value="">Selecione</option>
            <option value="Instagram">Instagram</option>
            <option value="Facebook">Facebook</option>
            <option value="Whatsapp">WhatsApp</option>
            <option value="Email">Email</option>
            <option value="Indicacao">Indicação</option>
          </select>

          <label>
            Observação:
            <textarea
              name="observacao"
              value={newClient.observacao}
              onChange={handleInputChange}
            ></textarea>
          </label>
          <button type="button" onClick={handleSaveClient}>
            Salvar
          </button>
        </form>
      </Modal>
    </>
  );
};

export default ClientList;