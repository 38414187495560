import { useState, useEffect, useCallback } from 'react';
import Sidebar from '../../components/SideBar';
import './agendamento.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import Modal from '../../components/Modal';
import { getFirestore, collection, addDoc, getDocs, query, where, updateDoc, doc, serverTimestamp } from 'firebase/firestore';
import { getApp } from 'firebase/app';
import { getAuth } from 'firebase/auth'; // Corrigido: Importando autenticação do Firebase
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Agendamento() {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDay, setSelectedDay] = useState(null);
  const [titulo, setTitulo] = useState("");
  const [horaInicial, setHoraInicial] = useState("");
  const [horaFinal, setHoraFinal] = useState("");
  const [detalhes, setDetalhes] = useState("");
  const [agendamentos, setAgendamentos] = useState([]);
  const [selectedAppointment, setSelectedAppointment] = useState(null);

  const app = getApp();
  const db = getFirestore(app);


  // Função para calcular os dias vazios antes do dia 1
  const getDiasVazios = () => {
    const primeiroDia = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1).getDay();
    return Array.from({ length: primeiroDia }, (_, i) => null);
  };

  // Calcula o número de dias no mês atual
  const diasNoMes = Array.from(
    { length: new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate() },
    (_, i) => i + 1
  );

  const diasVazios = getDiasVazios();

  // Navegação entre meses
  const handlePreviousMonth = () => {
    const prevMonth = new Date(currentDate.setMonth(currentDate.getMonth() - 1));
    setCurrentDate(new Date(prevMonth));
  };

  const handleNextMonth = () => {
    const nextMonth = new Date(currentDate.setMonth(currentDate.getMonth() + 1));
    setCurrentDate(new Date(nextMonth));
  };

  // Função para abrir o modal de criação de agendamento
  const handleDayClick = (day, e) => {
    const agendamentosNoDia = agendamentos.filter((agendamento) => agendamento.dia === day);

    setSelectedDay(day);
    setSelectedAppointment(null);

    if (agendamentosNoDia.length > 0) {
      const target = e.target;
      if (target.classList.contains("agendamento")) {
        const clickedAgendamento = agendamentosNoDia.find(agendamento => agendamento.titulo === target.innerText);
        setSelectedAppointment(clickedAgendamento);
        setIsModalOpen(true);
      } else {
        setIsModalOpen(true);
      }
    } else {
      setIsModalOpen(true);
    }
  };

  // Função para salvar o agendamento no Firebase
  const salvarAgendamento = async () => {
    const auth = getAuth();
    const user = auth.currentUser; // Obtém o usuário logado
    if (!user) {
      toast.error("Usuário não autenticado.");
      return;
    }

    const agendamentoConflitante = agendamentos.find(
      (agendamento) =>
        agendamento.dia === selectedDay &&
        (agendamento.horaInicial === horaInicial || agendamento.horaFinal === horaFinal)
    );

    if (agendamentoConflitante) {
      toast.warning("Já existe um agendamento neste horário.");
      return;
    }

    if (titulo.trim() !== "" && horaInicial.trim() !== "" && horaFinal.trim() !== "" && detalhes.trim() !== "") {
      try {
        await addDoc(collection(db, 'agendamentos'), {
          dia: selectedDay,
          mes: currentDate.getMonth() + 1,
          ano: currentDate.getFullYear(),
          titulo: titulo,
          horaInicial: horaInicial,
          horaFinal: horaFinal,
          detalhes: detalhes,
          userId: user.uid, // Adicionando o ID do usuário que fez o agendamento
          status: "Agendado", // Status como "Agendado" por padrão
          timestamp: serverTimestamp(),
        });
        toast.success("Agendamento salvo com sucesso!");
        setIsModalOpen(false);
        setTitulo("");
        setHoraInicial("");
        setHoraFinal("");
        setDetalhes("");
        carregarAgendamentos();
      } catch (error) {
        toast.error("Erro ao salvar o agendamento.");
      }
    } else {
      toast.warning("Por favor, preencha todos os campos.");
    }
  };

  // Função para verificar se o agendamento expirou
  const verificarExpirado = useCallback((horaInicial, dia) => {
    const dataAgendamento = new Date(currentDate.getFullYear(), currentDate.getMonth(), dia, ...horaInicial.split(":"));
    const dataAtual = new Date();

    // Verifica se a data do agendamento é anterior à data atual
    return dataAgendamento < dataAtual;
  }, [currentDate]);

  const carregarAgendamentos = useCallback(async () => {
    try {
      const auth = getAuth();
      const user = auth.currentUser; // Obtém o usuário logado
      if (!user) {
        toast.error("Usuário não autenticado.");
        return;
      }

      // Agora buscamos os agendamentos que pertencem ao usuário logado
      const q = query(
        collection(db, 'agendamentos'),
        where("mes", "==", currentDate.getMonth() + 1),
        where("ano", "==", currentDate.getFullYear()),
        where("userId", "==", user.uid) // Adicionando a verificação para o usuário logado
      );
      const querySnapshot = await getDocs(q);
      const loadedAgendamentos = [];
      querySnapshot.forEach((doc) => {
        const agendamento = { id: doc.id, ...doc.data() };

        // Verificar se o agendamento está expirado e atualizar o status
        if (agendamento.status === "Aguardando" && verificarExpirado(agendamento.horaInicial, agendamento.dia)) {
          agendamento.status = "Expirado";
          updateStatus(agendamento.id, "Expirado");  // Atualiza o status no Firebase
        }

        loadedAgendamentos.push(agendamento);
      });
      setAgendamentos(loadedAgendamentos);
    } catch (error) {
      console.error("Erro ao carregar agendamentos:", error);
      toast.error("Erro ao carregar agendamentos.");
    }
  }, [currentDate, db, verificarExpirado]);

  // useEffect para carregar agendamentos quando o mês mudar
  useEffect(() => {
    carregarAgendamentos();
  }, [carregarAgendamentos]);

  useEffect(() => {
    carregarAgendamentos();
  }, [currentDate, carregarAgendamentos]); // Corrigido, agora "carregarAgendamentos" está na lista de dependências

  // Função para renderizar os agendamentos no calendário
  const renderizarAgendamentos = (day) => {
    return agendamentos
      .filter((agendamento) => agendamento.dia === day)
      .map((agendamento, index) => (
        <div
          key={index}
          className="agendamento"
          style={{
            backgroundColor: getAgendamentoStatusColor(agendamento.status),
          }}
          onClick={(e) => handleDayClick(day, e)}
        >
          {agendamento.titulo}
        </div>
      ));
  };

  const atualizarAgendamento = async () => {
    if (selectedAppointment) {
      try {
        const agendamentoRef = doc(db, 'agendamentos', selectedAppointment.id);
        await updateDoc(agendamentoRef, {
          titulo,
          horaInicial,
          horaFinal,
          detalhes,
        });
        toast.success("Agendamento atualizado com sucesso!");
        setIsModalOpen(false);
        carregarAgendamentos();
      } catch (error) {
        console.error("Erro ao atualizar o agendamento:", error);
        toast.error("Erro ao atualizar o agendamento.");
      }
    } else {
      toast.warning("Nenhum agendamento selecionado para atualizar.");
    }
  };

  useEffect(() => {
    if (selectedAppointment) {
      setTitulo(selectedAppointment.titulo);
      setHoraInicial(selectedAppointment.horaInicial);
      setHoraFinal(selectedAppointment.horaFinal);
      setDetalhes(selectedAppointment.detalhes);
    }
  }, [selectedAppointment]);

  // Função para retornar a cor de fundo com base no status do agendamento
  const getAgendamentoStatusColor = (status) => {
    switch (status) {
      case "Atendido":
        return "#a8e6a1"; // Verde claro
      case "Cancelado":
        return "#f8a8a8"; // Vermelho claro
      case "Expirado":
        return "#d3d3d3"; // Cinza
      case "Agendado":
      default:
        return "#add8e6"; // Azul claro
    }
  };

  // Funções para alterar o status no modal
  const marcarComoAtendido = async () => {
    if (selectedAppointment) {
      await updateStatus(selectedAppointment.id, "Atendido");
      toast.success("Agendamento marcado como atendido.");
      setIsModalOpen(false);
    } else {
      console.log("Nenhum agendamento selecionado para marcar como atendido.");
    }
  };

  const cancelarAgendamento = async () => {
    if (selectedAppointment) {
      await updateStatus(selectedAppointment.id, "Cancelado");
      toast.success("Agendamento cancelado.");
      setIsModalOpen(false);
      setIsModalOpen(false);
    } else {
      console.log("Nenhum agendamento selecionado para cancelar.");
    }
  };

  const updateStatus = async (id, newStatus) => {
    try {
      const agendamentoRef = doc(db, 'agendamentos', id);
      await updateDoc(agendamentoRef, { status: newStatus });
      carregarAgendamentos();
    } catch (error) {
      console.error("Erro ao atualizar o status do agendamento:", error);
      toast.error("Erro ao atualizar status.");
    }
  };

  const isToday = (day) => {
    const today = new Date();
    return (
      day === today.getDate() &&
      currentDate.getMonth() === today.getMonth() &&
      currentDate.getFullYear() === today.getFullYear()
    );
  };

  return (
    <div>
      <Sidebar />

      <div className='container'>
        <h2>Agendamentos</h2>

        {/* Navegação do calendário */}
        <div className="calendar-navigation">
          <h2>{currentDate.toLocaleDateString('pt-BR', { month: 'long', year: 'numeric' })}</h2>
          <div>
            <button onClick={handlePreviousMonth}>
              <FontAwesomeIcon icon={faAngleLeft} className="i" />
            </button>
            <button onClick={handleNextMonth}>
              <FontAwesomeIcon icon={faAngleRight} className="i" />
            </button>
          </div>
        </div>

        <div className="content content-day">
          <div className="calendar-grid">
            <div className="calendar-header">Dom</div>
            <div className="calendar-header">Seg</div>
            <div className="calendar-header">Ter</div>
            <div className="calendar-header">Qua</div>
            <div className="calendar-header">Qui</div>
            <div className="calendar-header">Sex</div>
            <div className="calendar-header">Sáb</div>

            {[...diasVazios, ...diasNoMes].map((day, index) => (
              <div
                key={index}
                className={`calendar-day ${day ? (isToday(day) ? 'today' : '') : 'empty-day'}`}
                onClick={day ? (e) => handleDayClick(day, e) : undefined}
              >
                {day && <span>{day}</span>}
                {day && renderizarAgendamentos(day)}
              </div>
            ))}
          </div>
        </div>

        <Modal isOpen={isModalOpen} onRequestClose={() => setIsModalOpen(false)}>
          {selectedAppointment ? (
            <div className="modal-detail">
              <h3 className="modal-title">Editar Agendamento</h3>
              <p><strong >Status:</strong>
                <span style={{
                  backgroundColor: getAgendamentoStatusColor(selectedAppointment.status),
                  color: "#fff", // Texto branco para melhor contraste
                  padding: "0 2px",
                  borderRadius: "5px",
                }}>
                  {selectedAppointment.status}
                </span>
              </p>
              {/* Campos editáveis */}
              <label>Título:</label>
              <input
                type="text"
                value={titulo}
                onChange={(e) => setTitulo(e.target.value)}
                placeholder="Título do Agendamento"
              />

              <label>Hora Inicial:</label>
              <input
                type="time"
                value={horaInicial}
                onChange={(e) => setHoraInicial(e.target.value)}
              />

              <label>Hora Final:</label>
              <input
                type="time"
                value={horaFinal}
                onChange={(e) => setHoraFinal(e.target.value)}
              />

              <label>Detalhes:</label>
              <textarea
                value={detalhes}
                onChange={(e) => setDetalhes(e.target.value)}
                placeholder="Detalhes do Agendamento"
              ></textarea>

              {/* Botões para atualizar ou alterar status */}
              <div className="modal-buttons">
                <button className="btnSalvar" onClick={atualizarAgendamento}>Salvar Alterações</button>
                {selectedAppointment.status !== "Atendido" && selectedAppointment.status !== "Cancelado" && (
                  <>
                    <button className="btnCancelar" onClick={cancelarAgendamento}>Cancelar</button>
                    <button className="btnAtender" onClick={marcarComoAtendido}>Marcar como Atendido</button>
                  </>
                )}
              </div>
            </div>
          ) : (
            <div className="modal-details">
              <h3 className="modal-title">Criar Agendamento</h3>
              <label>Título:</label>
              <input
                type="text"
                value={titulo}
                onChange={(e) => setTitulo(e.target.value)}
                placeholder="Um título curto"
              />
              <label>Hora Inicial:</label>
              <input
                type="time"
                value={horaInicial}
                onChange={(e) => setHoraInicial(e.target.value)}
                placeholder="Hora Inicial"
              />
              <label>Hora Final:</label>
              <input
                type="time"
                value={horaFinal}
                onChange={(e) => setHoraFinal(e.target.value)}
                placeholder="Hora Final"
              />
              <label>Detalhes da reunião:</label>
              <textarea
                value={detalhes}
                onChange={(e) => setDetalhes(e.target.value)}
                placeholder="Detalhes"
              />
              <br />
              <button onClick={salvarAgendamento}>Salvar</button>
            </div>
          )}
        </Modal>

      </div >
    </div >
  );
}

export default Agendamento;