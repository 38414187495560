import React, { useEffect, useState } from 'react';
import './demandas.css';
import Sidebar from '../../components/SideBar';
import Modal from '../../components/Modal';
import Pagination from '../../components/Paginacao';
import { auth, db } from '../../auth/firebaseConnection';
import { collection, getDocs, doc, updateDoc } from 'firebase/firestore';
import { toast } from 'react-toastify';
import DatePicker from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import isUserMaster from '../../routes/isUserMaster';
import jsPDF from 'jspdf'; // Importando a biblioteca jspdf
import 'jspdf-autotable'; // Importando a extensão para gerar tabelas
import Logo from '../../assets/img/logoAzul.png';


function Demandas() {
  const [emails, setEmails] = useState([]);
  const [demandas, setDemandas] = useState([]);
  const [userEmail, setUserEmail] = useState('');
  const [userMaster, setUserMaster] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [filteredDemandas, setFilteredDemandas] = useState([]);
  const [filterEmail, setFilterEmail] = useState('');
  const [filterStatus, setFilterStatus] = useState('');
  const [filterContrato, setFilterContrato] = useState(''); // Novo estado para o iltro do contrato
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [totalValue, setTotalValue] = useState(0);
  const [totalAReceber, setTotalAReceber] = useState(0);
  const [totalLiquido, setTotalLiquido] = useState(0);
  const [totalSemImposto, setTotalSemImposto] = useState(0);
  const [totalImposto, setTotalImposto] = useState(0);

  const [modalText, setModalText] = useState('');
  const [isTextModalOpen, setIsTextModalOpen] = useState(false); // Novo estado para o modal de texto

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDemanda, setSelectedDemanda] = useState({ nFiscal: undefined });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const user = auth.currentUser;

        if (user) {
          setUserEmail(user.email);

          // Atualizado para usar isUserMaster
          const isMaster = await isUserMaster();
          setUserMaster(isMaster);

          const demandasRef = collection(db, 'demandas');
          const demandasSnapshot = await getDocs(demandasRef);

          const demandasData = [];

          await Promise.all(demandasSnapshot.docs.map(async (demandaDoc) => {
            const subcolecoesRef = collection(demandaDoc.ref, 'subcolecoes');
            const subcolecoesSnapshot = await getDocs(subcolecoesRef);

            subcolecoesSnapshot.forEach((subcolecaoDoc) => {
              const data = {
                id: subcolecaoDoc.id,
                email: demandaDoc.id,
                ...subcolecaoDoc.data(),
              };

              // Atualizado para verificar userMaster
              if (userMaster || user.email === data.email) {
                demandasData.push(data);
              }
            });
          }));

          // Ordenar as demandas pela data de início em ordem decrescente (mais recente primeiro)
          const sortedDemandas = demandasData.sort((a, b) => b.dataInicio.toDate() - a.dataInicio.toDate());


          setDemandas(sortedDemandas);
          setFilteredDemandas(sortedDemandas);
        }
      } catch (error) {
        console.error('Erro ao buscar demandas:', error);
        toast.error('Erro ao buscar demandas', { className: 'toast-css' });
      }
    };

    fetchData();
  }, [userMaster]);

  const calculateTotals = (data) => {
    const total = data.reduce((acc, demanda) => acc + parseFloat(demanda.valorTotal), 0);
    const totalReceber = data.reduce((acc, demanda) => acc + parseFloat(demanda.aReceber), 0);
    const totalLiquido = total - totalReceber;

    // Calcule o valor total sem imposto (8.5% do valor total líquido)
    const imposto = total * 0.11;
    const totalSemImposto = totalLiquido - imposto;


    setTotalValue(total);
    setTotalAReceber(totalReceber);
    setTotalLiquido(totalLiquido);
    setTotalSemImposto(totalSemImposto);
    setTotalImposto(imposto);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredDemandas.slice(indexOfFirstItem, indexOfLastItem);

  const onNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const onPrevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const handleFilter = () => {
    let filteredData = demandas;

    if (filterEmail) {
      filteredData = filteredData.filter((demanda) => demanda.email === filterEmail);
    }

    if (filterStatus) {
      filteredData = filteredData.filter((demanda) => demanda.status === filterStatus);
    }

    if (filterContrato) {
      filteredData = filteredData.filter((demanda) => demanda.contrato.includes(filterContrato));
    }

    if (startDate && endDate) {
      filteredData = filteredData.filter(
        (demanda) =>
          demanda.dataInicio.toDate() >= startDate && demanda.dataInicio.toDate() <= endDate
      );
    }

    calculateTotals(filteredData);

    setFilterEmail('');
    setFilterStatus('');
    setFilterContrato('');
    setEndDate('');
    setStartDate('');

    setFilteredDemandas(filteredData);

    setCurrentPage(1);
  };

  const handleEditClick = (demanda) => {
    setSelectedDemanda(demanda);
    setIsModalOpen(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedDemanda((prevDemanda) => ({
      ...prevDemanda,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    try {
      const { id, email } = selectedDemanda;
      const subcolecaoRef = doc(db, 'demandas', email, 'subcolecoes', id);

      const updatedData = {
        ged: selectedDemanda.ged,
        contrato: selectedDemanda.contrato,
        objeto: selectedDemanda.objeto,
        dataInicio: selectedDemanda.dataInicio,
        dataTermino: selectedDemanda.dataTermino,
        horas: selectedDemanda.horas,
        valorHora: selectedDemanda.valorHora,
        valorTotal: selectedDemanda.valorTotal,
        imposto: selectedDemanda.imposto,
        aReceber: selectedDemanda.aReceber,
        nFiscal: selectedDemanda.nFiscal,
        status: selectedDemanda.status,
        formaPagamento: selectedDemanda.formaPagamento,
        observacaoPagamento: selectedDemanda.observacaoPagamento,
        dataEmissao: selectedDemanda.dataEmissao || null,
        dataPagamento: selectedDemanda.dataPagamento || null,
      };

      await updateDoc(subcolecaoRef, updatedData);

      toast.success('Demanda atualizados com sucesso!', { className: 'toast-css' });

      // Atualize a demanda no estado local
      setDemandas((prevDemandas) =>
        prevDemandas.map((demanda) =>
          demanda.id === selectedDemanda.id ? { ...demanda, ...updatedData } : demanda
        )
      );

      setFilteredDemandas((prevFilteredDemandas) =>
        prevFilteredDemandas.map((demanda) =>
          demanda.id === selectedDemanda.id ? { ...demanda, ...updatedData } : demanda
        )
      );


      closeModal();
    } catch (error) {
      console.error('Erro ao salvar dados da demanda:', error);
      toast.error('Erro ao salvar dados da demanda', { className: 'toast-css' });
    }
  };

  const closeModal = () => {
    setSelectedDemanda(null);
    setIsModalOpen(false);
  };

  // Listar email do DB
  useEffect(() => {
    const fetchData = async () => {
      const usersCollection = collection(db, 'users');
      const usersSnapshot = await getDocs(usersCollection);
      const emailsArray = usersSnapshot.docs.map(doc => doc.data().email);
      setEmails(emailsArray);
    };

    fetchData();
  }, []);

  // Funções para o modal de texto
  const handleOpenTextModal = (text) => {
    setModalText(text);
    setIsTextModalOpen(true);
  };

  const handleCloseTextModal = () => {
    setIsTextModalOpen(false);
    setModalText('');
  };

  // Função para gerar o PDF com os dados filtrados
  const generatePDF = () => {
    const doc = new jsPDF();

    // Adicionando a imagem do logo no topo
    doc.addImage(Logo, 'PNG', 10, 10, 30, 15); // (imagem, formato, x, y, largura, altura)

    // Adicionando um título abaixo do logo
    doc.setFontSize(16);
    doc.text('Relatório de Demandas', 70, 35); // Ajuste as coordenadas conforme necessário

    // Gerando a tabela com os dados das demandas (ajustar a posição conforme necessário)
    const tableColumn = ['Contrato', 'Horas', 'Valor Hora', 'Valor Total', 'Imposto', 'A Receber', 'N. Fiscal', 'Status'];
    const tableRows = [];

    filteredDemandas.forEach(demanda => {
      const demandaData = [
        demanda.contrato,
        demanda.horas,
        demanda.valorHora,
        demanda.valorTotal,
        demanda.imposto,
        demanda.aReceber,
        demanda.nFiscal,
        demanda.status
      ];
      tableRows.push(demandaData);
    });

    doc.autoTable({
      startY: 50, // Ajuste a posição inicial da tabela para que não sobreponha a imagem
      head: [tableColumn],
      body: tableRows,
      theme: 'grid',
      headStyles: { fillColor: '#6a7fff' }, // Alterando a cor de fundo do cabeçalho
    });

    // Adicionando os valores na parte inferior do PDF
    const pageHeight = doc.internal.pageSize.height; // Altura da página
    let currentYPosition = pageHeight - 60; // Definindo uma posição 60 unidades acima da borda inferior da página

    doc.setFontSize(12);
    doc.text(`Total: ${parseFloat(totalValue).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`, 14, currentYPosition);
    currentYPosition += 10; // Movendo a posição vertical para a próxima linha
    doc.text(`Total Consultor: ${parseFloat(totalAReceber).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`, 14, currentYPosition);
    currentYPosition += 10;
    doc.text(`Total Líquido: ${parseFloat(totalLiquido).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`, 14, currentYPosition);
    currentYPosition += 10;
    doc.text(`Total Imposto (8.5%): ${parseFloat(totalImposto).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`, 14, currentYPosition);
    currentYPosition += 10;
    doc.text(`Total Empresa: ${parseFloat(totalSemImposto).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`, 14, currentYPosition);

    // Salva o PDF
    doc.save('relatorio_demandas.pdf');
  };

  return (
    <div>
      <Sidebar />
      <div className="container">
        <h1>Demandas</h1>
        <div className="filter-section content">
          {userMaster &&
            <select
              className="filter-input"
              type="text"
              placeholder="Filtrar por e-mail"
              value={filterEmail}
              onChange={(e) => setFilterEmail(e.target.value)}
            >
              <option>Todos</option>
              {emails.map((email, index) => (
                <option key={index}>{email}</option>
              ))}
            </select>
          }
          <select
            className="filter-input"
            type="text"
            placeholder="Filtrar por status"
            value={filterStatus}
            onChange={(e) => setFilterStatus(e.target.value)}
          >
            <option>Todas</option>
            <option>Aceito</option>
            <option>Assinado</option>
            <option>Relatório</option>
            <option>Emitido</option>
            <option>Pago</option>
            <option>Cancelado</option>
          </select>
          <input
            type="text"
            className="filter-input"
            placeholder="Filtrar por contrato"
            value={filterContrato}
            onChange={(e) => setFilterContrato(e.target.value)}
          />
          <div className="custom-datepicker">
            <DatePicker
              placeholderText="Data inicio"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              dateFormat="dd/MM/yyyy" // Formato desejado
            />
            <DatePicker
              placeholderText="Data final"
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              dateFormat="dd/MM/yyyy" // Formato desejado
            />
          </div>
          <button className="filter-button" onClick={handleFilter}>
            Filtrar
          </button>
        </div>

        <div className="content content-table-demandas">
          <table className="tableDemandas">
            <thead>
              <tr>
                {userEmail && userMaster && <th>E-mail</th>}
                {userEmail && userMaster && <th>GED</th>}
                <th>Contrato</th>
                <th>Objeto</th>
                <th>Data Início</th>
                <th>Data Término</th>
                <th>Horas</th>
                <th>Valor Hora</th>
                <th>Valor Total</th>
                <th>Imposto</th>
                <th>A Receber</th>
                <th>N. Fiscal</th>
                <th>Data Emissão</th>
                <th>Data Pagamento</th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((demanda, index) => (
                <tr key={index}>
                  {userEmail && userMaster && <td className='tdLimitada'>{demanda.email}</td>}
                  {userEmail && userMaster && <td>{demanda.ged}</td>}
                  <td>{demanda.contrato}</td>
                  <td className="objeto" onClick={() => handleOpenTextModal(demanda.objeto)}>
                    {demanda.objeto.length > 50 ? demanda.objeto.substring(0, 50) + '...' : demanda.objeto}
                  </td>
                  <td>{demanda.dataInicio.toDate().toLocaleDateString()}</td>
                  <td>{demanda.dataTermino.toDate().toLocaleDateString()}</td>
                  <td>{demanda.horas}</td>
                  <td>{demanda.valorHora}</td>
                  <td>{demanda.valorTotal}</td>
                  <td>{demanda.imposto}</td>
                  <td>{demanda.aReceber}</td>
                  <td>{demanda.nFiscal}</td>
                  <td>{demanda.dataEmissao}</td>
                  <td>{demanda.dataPagamento}</td>
                  <td>{demanda.status}</td>
                  <td className="btn-edit" onClick={() => handleEditClick(demanda)}>
                    <FontAwesomeIcon icon={faPencilAlt} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <Modal isOpen={isModalOpen} onRequestClose={closeModal}>
          <h2>Atualizar Demanda</h2>

          <div className='children-modal'>
            {userEmail && userMaster &&
              <>
                <div>
                  <label>GED</label>
                  <input
                    value={selectedDemanda?.ged}
                    onChange={handleInputChange}
                    name="ged"
                  />


                  <label>Contrato</label>
                  <input
                    value={selectedDemanda?.contrato}
                    onChange={handleInputChange}
                    name="contrato"
                  />

                  <label>Objeto</label>
                  <input
                    value={selectedDemanda?.objeto}
                    onChange={handleInputChange}
                    name="objeto"
                  />


                  <label>Horas</label>
                  <input
                    value={selectedDemanda?.horas}
                    onChange={handleInputChange}
                    name="horas"
                  />

                  <label>Valor hora</label>
                  <input
                    value={selectedDemanda?.valorHora}
                    onChange={handleInputChange}
                    name="valorHora"
                  />

                  <label>Forma de pagamento</label>
                  <select
                    value={selectedDemanda?.formaPagamento}
                    onChange={handleInputChange}
                    name="formaPagamento"
                  >
                    <option >Outros</option>
                    <option >Pix</option>
                    <option >Crédito</option>
                    <option >Débito</option>
                    <option >Boleto</option>
                    <option >Transferência</option>
                  </select>
             
                  <label>Observação pagamento</label>
                  <input
                    value={selectedDemanda?.observacaoPagamento}
                    onChange={handleInputChange}
                    name="observacaoPagamento"
                  />
                </div>

                <div>
                  <label>Valor total</label>
                  <input
                    value={selectedDemanda?.valorTotal}
                    onChange={handleInputChange}
                    name="valorTotal"
                  />

                  <label>Imposto</label>
                  <select
                    value={selectedDemanda?.imposto}
                    onChange={handleInputChange}
                    name="imposto"
                  >
                    <option value="parceiro">Parceiro (40%)</option>
                    <option value="Teresina">Teresina (30%)</option>
                    <option value="viagem">Viagem (25%)</option>
                    <option value="viagem2">Viagem2 (20%)</option>
                    <option value="particular">Particular (11%)</option>
                  </select>

                  <label>A receber</label>
                  <input
                    value={selectedDemanda?.aReceber}
                    onChange={handleInputChange}
                    name="aReceber"
                  />
                  <label>Nota Fiscal</label>
                  <input
                    value={selectedDemanda?.nFiscal}
                    onChange={handleInputChange}
                    name="nFiscal"
                  />
                  <label>Data Emissão NF</label>
                  <input
                    type='date'
                    value={selectedDemanda?.dataEmissao || ''}
                    onChange={(e) => handleInputChange({ target: { name: 'dataEmissao', value: e.target.value } })}
                  />

                  <label>Data Pagamento</label>
                  <input
                    type='date'
                    value={selectedDemanda?.dataPagamento || ''}
                    onChange={(e) => handleInputChange({ target: { name: 'dataPagamento', value: e.target.value } })}
                  />

                </div>
              </>
            }
          </div>
          <div>
            <label>STATUS</label>
            <select
              value={selectedDemanda?.status}
              onChange={handleInputChange}
              name="status"
            >
              <option>Selecione</option>
              {userEmail && userMaster &&
                <>
                  <option>Aceito</option>
                  <option>Assinado</option>
                  <option>Emitido</option>
                  <option>Pago</option>
                  <option>Cancelado</option>
                </>
              }
              <option>Relatório</option>
            </select>
          </div>


          <button onClick={handleSave}>Salvar</button>
        </Modal >

        {/* Modal de texto objeto */}
        {isTextModalOpen && (
          <div className="modal-text-overlay" onClick={handleCloseTextModal}>
            <div className="modal-text-content" onClick={(e) => e.stopPropagation()}>
              <button className="modal-text-close" onClick={handleCloseTextModal}>X</button>
              <h4>Objeto da contratação</h4>
              <br></br>
              <p>{modalText}</p>
            </div>
          </div>
        )}

        {/* Use o componente de paginação */}
        < div className="pagination" >
          <Pagination
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            totalItems={filteredDemandas.length}
            onNextPage={onNextPage}
            onPrevPage={onPrevPage}
          />
        </div >

        <div className="soma-valores">
          {userMaster &&
            <div className="valores">
              <h4>Valor Total:</h4>
              <span>{parseFloat(totalValue).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</span>
            </div>
          }
          <div className="valores">
            <h4>Total Consultor:</h4>
            <span> {parseFloat(totalAReceber).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</span>
          </div>
          {userMaster &&
            <div className="valores">
              <h4>Total Líquido: </h4>
              <span>{parseFloat(totalLiquido).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</span>
            </div>
          }
          {userMaster &&
            <div className="valores">
              <h4>Total Imposto (11%):</h4>
              <span>{parseFloat(totalImposto).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</span>
            </div>
          }
          {userMaster &&
            <div className="valores">
              <h4>Total Positive </h4>
              <span>{parseFloat(totalSemImposto).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</span>
            </div>
          }
        </div>
        <br></br>
        {userMaster &&
          <button className="filter-button" onClick={generatePDF}>
            Gerar Relatório
          </button>
        }
      </div >
    </div >
  );
}

export default Demandas;
